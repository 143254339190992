import axios from 'axios';
import Router from 'next/router';

axios.interceptors.response.use(null, function (error) {
  if (error?.response?.status === 503) {
    const location = error.response?.headers?.location ?? '/maintenance.html';
    Router.push(location);
  }
  return Promise.reject(error);
});
