import { createMedia } from '@artsy/fresnel';
// import { theme } from '@awning/components';

// const breakpoints = theme.breakpoints.map((b: string) => b.replace('px', ''));
const Responsive = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1280,
  },
});

export const mediaStyles = Responsive.createMediaStyle();

export const { Media, MediaContextProvider } = Responsive;
