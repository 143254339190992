import axios from 'axios';
import * as React from 'react';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import * as Sentry from '@sentry/node';
import { BaseStyles, Global, ThemeProvider, theme } from '@awning/components';

// @ts-ignore
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';
import { MediaContextProvider } from '@/src/Responsive';

// Awning component styles
import '@awning/components/dist/index.css';

// Awning chat css
import 'stream-chat-react/dist/css/index.css';
import '@awning/chat/dist/index.css';

// Axios config
import '../axios.config';

if (process.env.NEXT_PUBLIC_SENTRY_ENABLED && process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export default function App({ Component, pageProps }) {
  const router = useRouter();

  if (process.env.NODE_ENV !== 'development') {
    useSegment({ router }); //eslint-disable-line
  }

  React.useEffect(() => {
    // kick off the polyfill!
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <Head>
        <title>{process.env.NODE_ENV === 'development' ? '(DEV) - ' : ''}Awning</title>
      </Head>
      <ThemeProvider theme={theme}>
        <BaseStyles>
          <Global
            styles={`
            // We are not really supporting iPhone SE so just make the base font size even smaller
            @media screen and (max-width: 320px) {
              html {
                font-size: 14px;
              }
            }

            #__next {
              height: 100%;
              overflow: auto;
              overflow-x: hidden;
            }
              
            a {
              cursor: pointer;
              color: inherit; 
            }
              
            #nprogress .bar {
              z-index: 99999;
            }

          `}
          />
          <MediaContextProvider>
            <Component {...pageProps} />
          </MediaContextProvider>
        </BaseStyles>
      </ThemeProvider>
    </>
  );
}

const useSegment = ({ router }) => {
  const handleRouteChange = url => {
    axios.post('/api/events/page', {
      url: url || router.asPath,
      path: router.asPath.split('?')[0],
      query: router.query,
      route: router.route,
    });
  };

  React.useEffect(() => {
    if (router?.query?.retool) return;
    (async function () {
      // send an identify event
      await axios.post('/api/events/identify');

      // after that's done we send a page event
      handleRouteChange();
    })();
  }, []);

  React.useEffect(() => {
    if (router?.query?.retool) return;
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return null;
};
